import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { LightningUserFavorurite } from '../vendor/classes/user-favourite.enum';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { faChevronDown, faBell, faLanguage, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { resultIcons } from '../vendor/enum/result-icons.enum';
import {
    BookerType,
    CarbonAllowance,
    CarbonPolicySource,
    EnterpriseBasketService,
    EnterpriseSearchService,
    HelperRoutines,
    LogonService,
    Message,
    ModalOpenerService,
    ServiceType,
    UserService,
    WithSubscriptionComponent,
    BasketItem,
    User,
    Language
} from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../vendor/classes/modal-types.enum';
import { DDAService } from '../vendor/services/dda.service';
import {
    FullUserDetails,
    EnableFreshChat,
    EnableFreshChatValue,
    ServiceProvider
} from '@sabstravtech/obtservices/base';
import { ScriptLoaderService } from '../vendor/services/script-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { WCAGFocusHandler } from '../vendor/services/wcag-focus-handler.service';
import { LanguageService } from '../vendor/services/language.service';
import { ThemeService } from '../vendor/services/theme.service';
import { DOCUMENT } from '@angular/common';
// import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-results-header',
    templateUrl: './results-header.component.html',
    styleUrls: ['./results-header.component.scss'],
    animations: [
        trigger('flyInOut', [
            transition('* => *', [
                animate(
                    '350ms 0ms ease-in',
                    keyframes([
                        style({ opacity: 1, transform: 'translateY(0)' }),
                        style({ opacity: 1, transform: 'translateY(-5px)' }),
                        style({ opacity: 1, transform: 'translateY(-10px)' }),
                        style({ opacity: 1, transform: 'translateY(-15px)' }),
                        style({ opacity: 1, transform: 'translateY(-10px)' }),
                        style({ opacity: 1, transform: 'translateY(-5px)' }),
                        style({ opacity: 0, transform: 'translateY(0)' })
                    ])
                )
            ])
        ])
    ]
})
export class ResultsHeaderComponent extends WithSubscriptionComponent implements OnInit, OnChanges {
    @Input() public blank = false;
    @Input() public homeIcon = true;
    @Input() public logoutIcon = true;
    @Input() public transparent = false;
    @Input() public userIcon = true;
    @Input() public page = '';
    @Input() public basketStatus = 0;
    @Input() public openBasket: BasketItem[] = [];
    @Input() public isSubHidden = false;

    @ViewChild('main') main: ElementRef;
    @ViewChild('basket') basket: ElementRef;

    public overrideTravelPolicy: boolean = false;
    public resultsType: string;
    public display_user_data = false;
    public displayAnn = false;
    public user_info = null;
    private _lastUrl = '';
    public helpUrl: string = null;
    public supportMessage: string = null;
    public covidHubOpen = false;
    public BookerType = BookerType;
    public search_loading = false;
    public ShowUserOptionsToggle: boolean = false;
    private sub: Subscription = null;
    public annCount = 0;

    public userCarbonAllowance: CarbonAllowance;
    public carbonPolicyHeader = '';
    public startOfPeriod = '';
    public endOfPeriod = '';
    public showCarbonPolicy = false;
    public homepage: string = '';
    public toggleAnn = false;
    public live = false;
    public menuOpen = false;
    public showLiveRail = true;
    public isUserAdmin: boolean;
    public isRoot = false;
    public isSupportAgent = false;
    public isTmcAdmin: boolean;
    public hideCovidTest: boolean = false;
    public announcementsImgSrc: string = "https://images.sabscorp.com/images/enterprise/lightUK/greyAlert.svg";
    public announcementsImgSrcWhite: string = "https://images.sabscorp.com/images/enterprise/lightUK/whiteAlert.svg";
    public announcementsImg = this.announcementsImgSrc;
    resultItemType = resultIcons;
    name_initials: string;
    faChevronDown = faChevronDown;
    faBell = faBell;
    basketItemTypes: ServiceType[] = [];
    isMainPage: boolean = false;
    toggleLanguagesState: boolean = false;
    toggleUsefulInfoState: boolean = false;
    faLanguage = faLanguage;
    faChevronUp = faChevronUp;
    selectedLanguage: Language;
    availableLanguages: Language[] = [];
    logoUrl: string = null;
    showUsefulInformationDropdown: boolean = false;
    displayUsefulInformation: boolean;
    showUsefulInfoToggle: boolean;
    showGetUsThere: boolean = false;
    canBecomeUser: boolean = false;

    public contactUrl = this.helpers.isOnDev() || this.helpers.isOnStaging() ? "https://test.ctmlightning.co.uk/crown/contacts/" : "https://ctmlightning.co.uk/crown/contacts/";
    public feedbackUrl = "https://travelctmnorth-clients.freshdesk.com/support/home";
    public userGuidesUrl = "https://travelctm-north.co.uk/index.html";
    public priceMatchUrl = "https://www.ctmlightning.co.uk/crown/CTM_Price_Match_for_CCS.pdf";
    showBolt: boolean = true;
    constructor(
        // public readonly annService: AnnouncementService,
        public readonly basketService: EnterpriseBasketService,
        private readonly router: Router,
        private readonly modalService: ModalOpenerService,
        private readonly userService: UserService,
        public readonly searchService: EnterpriseSearchService,
        private readonly logonService: LogonService,
        private readonly ddService: DDAService,
        private readonly scriptLoaderService: ScriptLoaderService,
        public translator: TranslateService,
        private readonly cd: ChangeDetectorRef, // private readonly bookOnBehalfService: BookOnBehalfService // private readonly changeUserService: ChangeUserService
        private helpers: HelperRoutines,
        private wcagFocusHandlerService: WCAGFocusHandler,
        private languageService: LanguageService,
        protected themeService: ThemeService,
        @Inject(DOCUMENT) public document: Document
        // private auth: AuthService

    ) {
        super();
    }

    ngOnInit(): void {
        if (this.page === 'become user' && !this.userService.fullUserDetails.userData.value) {
            this.router.navigate(['search']);
        }
        this.getUserDropdownInfo();
        this.isRoot = this.userService.userIsRoot();
        this.isSupportAgent = this.userService.userIsSupportAgent();
        this.isTmcAdmin = this.userService.userIsTmcAdmin();
        this.canBecomeUser = this.userService.fullUserDetails.allDetails.value?.becomeUser;
        this.subscribe(this.userService.changeUser, (fullUser: FullUserDetails) => {
            if (fullUser && fullUser.userData.value) {
                this.isRoot = this.userService.userIsRoot();
                this.live = fullUser.userData.value.live;
                this.selectedLanguage = fullUser.allDetails.value?.selectedLanguage;
                this.availableLanguages = fullUser.allDetails.value?.availableLanguages;
                // this.userId = fullUser.userData.value.user_id;
                // if (this.page === 'results') {
                //     this.getUserCarbonPolicy();
                // }

            }
            this.homepage = this.userService.getUserFavoriteValue(LightningUserFavorurite.homepage);
            this.showBolt = !(this.userService.getUserFavoriteObject(LightningUserFavorurite.DisableDoorToDoor) as { disableDoorToDoor: boolean; })?.disableDoorToDoor;
            const showGetUsThere: { value: boolean; } = this.userService.getUserFavoriteObject(LightningUserFavorurite.EnableDoorToDoorPlus);
            const isSelfBooker: boolean = !!(this.userService.fullUserDetails.userData.value.booker_type === BookerType.SelfBook);
            this.showGetUsThere = showGetUsThere?.value && !isSelfBooker;
            this.showUsefulInformationDropdown = (this.userService.getUserFavoriteObject(LightningUserFavorurite.UsefulInformation) as {
                enable: boolean;
            })?.enable;
            this.hideCovidTest = (this.userService.getUserFavoriteObject(LightningUserFavorurite.HideCovidTest) as {
                value: boolean;
            }
            )?.value;
            // show/hide Live Rail tab
            this.showLiveRail =
                this.userService.userHasServiceProvider(ServiceType.Rail, ServiceProvider.Evolvi) ||
                this.userService.userHasServiceProvider(
                    ServiceType.Rail,
                    ServiceProvider.Trainline
                );
            if (!this.userService.freshChatAdded) {

                const enableFreshChat: EnableFreshChatValue =
                    this.userService.getUserFavoriteObject<EnableFreshChat>(
                        LightningUserFavorurite.EnableFreshChat
                    )?.value;

                if (enableFreshChat?.enable) {
                    this.initFreshChat(enableFreshChat);
                }
            }

        });
        this.subscribe(this.userService.fullUserDetails.allDetails, (data: User) => {
            this.canBecomeUser = data.becomeUser;
        });
        this.initialize();
        this.subscribe(this.router.events, this.initialize.bind(this));

        this.isUserAdmin = this.userService.userIsAdmin() || this.userService.userIsCompanyAdmin();
        if (!this.userService.beamerAdded && this.themeService.isLightningEnv) {
            this.initBeamer().then(() => {
                const companyName = this.userService.fullUserDetails.userData.value.company_name;
                const bookerType = this.userService.fullUserDetails.userData.value.booker_type;
                (<any>window).beamer_config = {
                    product_id: 'pbKlAiRw44591', //DO NOT CHANGE: This is your product code 
                    user_company: companyName,
                    user_role: bookerType

                };

            }).catch(err => {
                console.warn(err);
            });
        }
        this.logoUrl = this.themeService.getLogo();

    }

    updateLanguage(id: string) {
        const payload = {
            selectedLanguage: id
        };

        this.userService.editUser(payload).subscribe((user: User) => {
            let languageUpdate = false;
            if (this.selectedLanguage.id !== user.selectedLanguage.id) {
                languageUpdate = true;
            }
            if (user) {
                if (languageUpdate) {
                    const selectedLanguage = user.selectedLanguage;
                    const availableLanguages = user.availableLanguages.map((lang: Language) => lang.isoCode);
                    this.languageService.changeLanguageFromUserDetails(selectedLanguage.name, selectedLanguage.isoCode, availableLanguages);
                }
                this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'Language has been successfully changed' });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.openBasket) {
            this.basketItemTypes = this.getBasketItemTypes(this.openBasket);
        }
    }

    initFreshChat(enableFreshchat: EnableFreshChatValue) {
        this.userService.freshChatAdded = true;
        console.warn('+++ FRESHCHAT INIT +++');
        this.scriptLoaderService
            .addScript('https://wchat.eu.freshchat.com/js/widget.js')
            .then(() => {
                const fcWidget = (<any>window).fcWidget;
                if (fcWidget) {
                    const self = this;
                    if (fcWidget) {
                        fcWidget.init({
                            token: enableFreshchat.token,
                            host: 'https://wchat.eu.freshchat.com',
                            firstName: self.userService.getUserForename(),
                            lastName: self.userService.getUserSurname(),
                            email: self.userService.getUserEmail(),
                            phone: self.userService.getFullPhoneNumber()
                        });
                        fcWidget.user.get((resp: any) => {
                            const status = resp && resp.status;
                            if (status !== 200) {
                                fcWidget.user.setProperties({
                                    firstName: self.userService.getUserForename(),
                                    lastName: self.userService.getUserSurname(),
                                    email: self.userService.getUserEmail(),
                                    phone: self.userService.getFullPhoneNumber()
                                });
                            }
                        });
                    }
                }
            });
    }


    async initBeamer(): Promise<void> {
        console.warn('initiating beamer');
        this.userService.beamerAdded = true;
        return this.scriptLoaderService
            .addScript('https://app.getbeamer.com/js/beamer-embed.js')
            .then((event) => {
                //Ensuring we're able to access the beamer push notification prompt as soon as it opens, bit hacky but all we can do with it being third party
                //@ts-ignore
                var native = Beamer.showNotificationPrompt;
                //@ts-ignore
                Beamer.showNotificationPrompt = function () {
                    native.apply(window, arguments);
                    //I've checked the beamer code and it opens the modal after 1500ms
                    setTimeout(() => {
                        let noButton = document.getElementById("pushActionRefuse");
                        let yesButton = document.getElementById("pushConfirmation");
                        if (noButton && yesButton) {
                            //This ensures that visually hidden descriptive text is available to screen readers
                            noButton.innerHTML = noButton.innerHTML + '<span class="sr-only">&nbsp;- Decline beamer push notifications</span>';
                            yesButton.innerHTML = yesButton.innerHTML + '<span class="sr-only">&nbsp;- Accept beamer push notifications</span>';
                            //This ensures the focus is trapped within the modal as it should be for modals
                            noButton.onkeydown = (event) => {
                                if (event.key === "Tab" && event.shiftKey) {
                                    event.preventDefault();
                                }
                            };
                            yesButton.onkeydown = (event) => {
                                if (event.key === "Tab" && !event.shiftKey) {
                                    event.preventDefault();
                                }
                            };
                            noButton.focus();
                        }
                    }, 1500);
                };
            });
    }

    udpateAnnCount(annCount: { annCount: number; announcements: Message[]; }) {
        this.annCount = annCount.annCount;
        const keys = Object.keys(annCount.announcements);

        for (let key of keys) {
            const page = annCount.announcements[key];
            // console.log(page);
            const found = page.filter((ann) => ann.collapse);
            if (found?.length) return; // break out, so we don't auto open
        }

        if (this.annCount) {
            this.toggleAnn = true;
        }
    }

    openAnn(): void {
        this.toggleAnn = true;
    }

    closeAnn(): void {
        this.toggleAnn = false;
    }

    /**
     * Focus container on page
     * @see window browser window element
     * @param containerName
     */
    focusContainer(containerName: string): boolean {
        try {
            this.cd.detectChanges();
            if (
                this.ddService[containerName] &&
                this.ddService[containerName].nativeElement ===
                document.getElementById(containerName)
            ) {
                const focusable = this.ddService[containerName].nativeElement.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                );
                var x = 0;
                while (
                    window.getComputedStyle(focusable[x]).display === 'none' ||
                    focusable[x].classList.contains('loading-title')
                ) {
                    // Don't focus on hidden element
                    x++;
                }
                focusable[x].focus();
                this.cd.detectChanges();
            } else {
                // Try with vanilla js
                const focusable = document
                    .getElementById(containerName)
                    .querySelectorAll(
                        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                    );
                var x = 0;
                // @ts-ignore
                while (
                    window.getComputedStyle(focusable[x]).display === 'none' ||
                    focusable[x].classList.contains('loading-title')
                ) {
                    x++;
                }
                // @ts-ignore
                focusable[x].focus();
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    initialize(): void {
        if (this.router.url !== this._lastUrl) {
            const split = this.router.url.split('/'); // used to determine the background
            const resultsType = split[split.length - 1];
            this.resultsType = resultsType.replace(/s$/, '');
            this._lastUrl = this.router.url;
        }
    }

    openAdminModal(): void {
        const url = this.helpers.isOnDev() ? environment.ADMIN_USER_PANEL_DEVELOP
            : this.helpers.isOnStaging() ? environment.ADMIN_USER_PANEL_STAGING
                : this.helpers.isOnMistest() ? environment.ADMIN_USER_PANEL_MISTEST
                    : environment.ADMIN_USER_PANEL;
        window.open(url, '_blank');
    }


    openSupport(): void {
        this.modalService.open(
            LightningModalTypes.ModalSupportEmailComponent,
            { centered: true },
            { message: this.supportMessage }
        );
    }

    showSupportEmail(): boolean {
        /* TODO
    const user = this.userService.getUserDetails();
    return Boolean((user && user.BookingAllowedFor === 'AgentBook') || (this.userService.userIsClient() && this.supportMessage));
    */
        return false;
    }

    logout(): void {
        this.modalService
            .open(
                LightningModalTypes.ModalConfirmComponent,
                {
                    centered: true
                },
                {
                    message: this.translator.instant('Please select as appropriate'),
                    btnOkText: this.translator.instant('Yes sign me out'),
                    btnCancelText: this.translator.instant('Sorry let me stay')
                }
            )
            .then((result) => {
                if (result) {
                    // if (document.cookie.includes('is.authenticated=true')) {
                    //     this.auth.logout({
                    //         logoutParams: {
                    //             returnTo: environment.redirectUrlScion // this is hardcoded to dev, need to check env properly like admin panel url
                    //         }
                    //     });
                    // }
                    this.logonService.logout();
                    this.searchService.reset();
                }
            });
    }


    resetAndBackToSearch(): void {
        this.userService.setBobo(null);
        this.searchService.reset();

        // this.landingPageService.getLandingPageTemplateMarkUp('corporate-homepage').pipe(take(1)).subscribe(data => {
        // if (data) {
        //   this.router.navigate(['landingPageBrand']);
        // } else {
        this.router.navigate(['search']);
        //   }
        // });
    }

    /**
  @desc - function to display the user data dropdown
  **/
    displayUserData(): void {
        this.display_user_data = !this.display_user_data;
        this.ShowUserOptionsToggle = !this.ShowUserOptionsToggle;
    }

    displayUsefulLinks() {
        this.displayUsefulInformation = !this.displayUsefulInformation;
        this.showUsefulInfoToggle = !this.showUsefulInfoToggle;
    }

    getUserDropdownInfo() {
        this.user_info = this.userService.getUserDropdownInfo();
        const fullName = this.user_info?.name.split(' ');
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        this.name_initials = initials.toUpperCase();

    }

    /**
  @desc - open the become user modal
  **/
    openBecomeUserModal(): void {
        this.display_user_data = false; // close the dropdown
        // this.changeUserService.showType = 'become';
        this.router.navigate(['/changeuser']);
    }

    openBehalfUserModal(): void {
        this.display_user_data = false; // close the dropdown
        // this.changeUserService.showType = 'book';
        this.router.navigate(['/changeuser']);
    }

    async endUserSession(): Promise<void> {
        await this.logonService.endBecomeUserSession();
        this.searchService.removeAllTravellers();
        this.basketService.resetBaskets();
        if (this.router.url === '/search') {
            // this is needed so we see the updated data
            window.location.reload();
        } else {
            this.router.navigate(['/search']);
        }
    }
    /**
  @desc - determine if we have a agency user that has become another user
  **/
    haveAgencyUser(): boolean {
        /* TODO
    if (this.userService.getAgencyUser()) {
      return true;
    }*/

        return false;
    }

    backToAgencyUser(): void {
        // TODO
    }

    /**
    @desc - login as the passed in agency user - call list users, then go to the logon service and user those functions
  **/
    loginAsAgencyUser(user: any): void {
        // TODO
    }

    getIconClass(searchObject: ServiceType) {
        switch (searchObject) {
            case ServiceType.Hotel:
                return 'ico-bed';
            case ServiceType.Flight:
                return 'ico-flight';
            case ServiceType.Rail:
            case ServiceType.InternationalRail:
                return 'ico-rail';
            case ServiceType.Car:
                return 'ico-car';
            case ServiceType.Lounge:
                return 'ico-m-lounge';
            case ServiceType.Parking:
                return 'ico-m-parking';
            case ServiceType.Cab:
                return 'ico-taxi';
            case ServiceType.Eurostar:
                return 'custom_icon-eurostar';
            case ServiceType.FastTrack:
                return 'ico-material ico-fast-track';
            // case ServiceType.COACH:
            //   return 'ico-bus';
            default:
                return 'ico-dot';
        }
    }

    cancelBookBehalf(): void {
        this.display_user_data = false; // close the dropdown
        // this.userService.onBehalfUser = undefined;
        // this.bookOnBehalfService.bookAsChange.emit(false);
    }

    isOnHomePage(): boolean {
        return this.router.url === '/landingPageBrand';
    }

    userIsClient(): boolean {
        return true; // TODO this.userService.userIsClient();
    }

    isOnHomePageOrBasket(): boolean {
        return this.router.url === '/main' || this.router.url.includes('/itinerary');
    }

    isOnLiveRail(): boolean {
        return this.router.url === '/live-rail';
    }

    isOnSearch(): boolean {
        return this.router.url === '/search';
    }

    showLanguages(): boolean {
        // if (this.userService.getAgencyUser()) {
        //   return Boolean(this.userService.getUserFavoriteValue(UserFavorurite.languages)) && Boolean(typeof this.userService.getUserAgentLanguage() === 'number');
        // } else {
        return Boolean(this.userService.getUserFavoriteValue(LightningUserFavorurite.languages));
        // }
    }

    hasRecentSearches(): boolean {
        return document.getElementById('recent_searches_container') ? true : false;
    }

    isOnResults(): boolean {
        return this.router.url.includes('results');
    }

    toggleCovidHub(): void {
        this.covidHubOpen = !this.covidHubOpen;
    }

    closeCovidHubIfFocusLeft(event, index): void {
        if (index === 0 && event.shiftKey && event.keyCode === 9) {
            this.covidHubOpen = false;
        }
        if (index === 1 && !event.shiftKey && event.keyCode === 9) {
            this.covidHubOpen = false;
        }
    }

    // moved as no longer part of search screen
    // getUserCarbonPolicy(): void {
    //     // grab outbound date
    //     const serviceType: ServiceType = this.searchTypes.find(type => this.results_type.includes(type.toLowerCase()));
    //     const userSearchDate = (<HotelEnterpriseSearchInterface>this.searchService.searches[serviceType]).checkin_date.format('YYYY-MM-DD');
    //     this.subscribe(
    //         this.searchService.getUserCarbonAllowance(this.userService?.bobo?.id ? '' + this.userService?.bobo?.id : '' + this.userId, userSearchDate),
    //         (data) => {
    //             if (data) {
    //                 this.userCarbonAllowance = data;
    //                 this.startOfPeriod = moment(data.startOfPeriod).format('DD.MM.YYYY');
    //                 this.endOfPeriod = moment(data.endOfPeriod).format('DD.MM.YYYY');

    //                 switch (this.userCarbonAllowance.carbonPolicySource) {
    //                     case CarbonPolicySource.Company:
    //                         this.carbonPolicyHeader = this.userCarbonAllowance.company.name;
    //                         break;
    //                     case CarbonPolicySource.Office:
    //                         this.carbonPolicyHeader = this.userCarbonAllowance.office.name;
    //                         break;
    //                     case CarbonPolicySource.User:
    //                         this.carbonPolicyHeader = `${this.userCarbonAllowance.user.forename} ${this.userCarbonAllowance.user.surname}`;
    //                         break;
    //                     default:
    //                         console.warn(
    //                             `+++ Unknown CarbonPolicySource ${this.userCarbonAllowance.carbonPolicySource}, cannot get title +++`
    //                         );
    //                 }
    //             }
    //         },
    //         (error) => {
    //             console.error(`+++ Error recovering user carbon policy: ${error} +++`);
    //         }
    //     );
    // }

    goToLiveRail(event = null) {
        if (event) {
            event.preventDefault();
        }
        // this.searchService.isLiveRail = true;
        this.router.navigate(['live-rail']);
    }

    checkToCloseMenu() {
        const context = this;
        setTimeout(function () {
            if (!document.getElementById('mobile_menu').contains(document.activeElement)) {
                context.menuOpen = false;
            }
        }, 0);
    }

    setBeamerIframeTitle() {
        setTimeout(() => { //Needs a timeout to allow the iframe to be initialised
            const iframe = document.getElementById("beamerNews");
            if (iframe) {
                iframe.setAttribute("title", "Beamer News Iframe");
                iframe.setAttribute("aria-label", "What's new on Lightning");
            }
        }, 0);
    }

    focusBasketButton() {
        this.wcagFocusHandlerService.skipToBasketClicked.emit();
    }

    redirect(page: string): void {
        this.router.navigate([page]);
    }

    getBasketItemTypes(basket: BasketItem[] = []): ServiceType[] {
        return basket.reduce((types, item) => {
            const itemType = item.service.type;
            if (!types.includes(itemType)) {
                types.push(itemType);
            }
            return types;
        }, []);
    }

    hasStandardLogo(): boolean {
        return this.logoUrl === "https://images.sabscorp.com/images/enterprise/lightUK/lightningLogo.svg";
    }

    openContactsUrl(): void {
        window.open(this.contactUrl, '_blank');
    }

    openFeedbackUrl(): void {
        window.open(this.feedbackUrl, '_blank');
    }

    openUserGuides(): void {
        window.open(this.userGuidesUrl, '_blank');
    }

    openPriceMatch(): void {
        window.open(this.priceMatchUrl, '_blank');
    }


}
