<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <ng-container *ngIf="flight?.detail as flightDetail">
      <div class="cart_item_heading" *ngIf="flightsToDisplay.detail.flights as flights">
        <ng-container
          *ngFor="let flightDetail of flights; let last = last; let flightIndex = index">
          <div i18n="@@MultiCityFlight" *ngIf="flight?.searchQuery?.multiDestination?.length">
            Multi-City Flight
          </div>
          <div [class.duplicate-item-wrapper]="duplicateBooking">
            <div *ngIf="duplicateBooking" class="duplicate-item-date">
              <i class="ico-flight" aria-hidden="true"></i>&nbsp;
              <span *ngIf="!flightIndex" i18n="@@flightBasketDeparts">Departs:</span>
              <span *ngIf="flightIndex" i18n="@@flightBasketReturns">Returns:</span>
              <strong>{{ flightDetail.dateTimeDeparting | sabsDate : 1 }}</strong>
              <strong>{{ flightDetail.dateTimeDeparting | date : 'HH:mm' }}</strong>
            </div>
            <div [class.duplicate-item-info]="duplicateBooking">
              <div class="airport-name-wrapper">
                <span>
                  <ng-container *ngIf="!duplicateBooking">
                    <i class="ico-flight" aria-hidden="true"></i>&nbsp;
                  </ng-container>

                  <span>{{ flightDetail.originAirportName }}</span
                  >&nbsp; <span i18n="@@flilghtSearchTo">to</span>&nbsp;
                  <span>{{ flightDetail.destinationAirportName }}</span>
                </span>
                <button
                  *ngIf="flightIndex === 0 && !duplicateBooking"
                  class="cart_item_remove ico-trash"
                  ngbTooltip="Remove {{ flightDescription }} from your {{
                    displayItinerary ? 'itinerary' : 'basket'
                  }}"
                  attr.aria-label="Remove {{ flightDetail.originAirportName }} to {{
                    flightDetail.destinationAirportName
                  }} flight from your {{ displayItinerary ? 'itinerary' : 'basket' }}"
                  i18n-ngbTooltip
                  (click)="remove.emit()"
                  id="remove-basketitem-{{ flight.id }}"></button>
                <img
                  *ngIf="flightIndex === 0 && !duplicateBooking"
                  src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/{{
                    displayItinerary ? 'luggage_icon' : 'basket_move'
                  }}.svg"
                  class="move_basket_icon"
                  role="button"
                  ngbTooltip="Move to different {{ displayItinerary ? 'itinerary' : 'basket' }}"
                  i18n-ngbTooltip
                  attr.aria-label="Move {{ flightDetail.originAirportName }} to {{
                    flightDetail.destinationAirportName
                  }} flight to different {{ displayItinerary ? 'itinerary' : 'basket' }}"
                  (click)="openChangeModal.emit()"
                  (keydown.enter)="openChangeModal.emit()"
                  (keydown.space)="openChangeModal.emit()"
                  tabindex="0"
                  id="share-basketitem-{{ flight.id }}" />
              </div>
              <div class="car_cart_item_from_point">
                <span i18n="@@flightBasketFlight">Flight:</span
                ><strong
                  >{{ flightDetail.marketingCarrier
                  }}{{ flightDetail.marketingFlightNumber }}</strong
                >
                ({{ flightDetail.marketingCarrierName }}) <br /><span
                  class="codeshare ng-star-inserted"
                  ><span i18n="@@flightBasketOperatedBy"> Operated By</span>
                  {{ flightDetail.operatingCarrierName }}</span
                >
                <app-erm-traveller
                  [joinerOrLeaver]="flight?.searchQuery?.joinerOrLeaver"></app-erm-traveller>
                <ng-container *ngIf="!duplicateBooking">
                  <br />
                  <strong>{{ flightDetail.originAirportName }}, </strong>7
                  <span class="terminal-span">
                    <span i18n="@@flightLegsTerminalDep">Terminal: </span>
                    <span>
                      {{
                        (flightDetail.originTerminal
                          ? flightDetail.originTerminal
                          : 'not available'
                        ) | translate
                      }}</span
                    >
                  </span>
                  <br />
                  {{ flightDetail.dateTimeDeparting | sabsDate : 5 }}
                  <br />
                </ng-container>
              </div>

              <div
                *ngIf="!duplicateBooking"
                class="car_cart_item_to_point"
                [ngClass]="{ final: last }">
                <strong>{{ flightDetail.destinationAirportName }}, </strong>
                <span class="terminal-span">
                  <span i18n="@@flightLegsTerminalDep">Terminal: </span>
                  <span>
                    {{
                      (flightDetail.destinationTerminal
                        ? flightDetail.destinationTerminal
                        : 'not available'
                      ) | translate
                    }}</span
                  >
                </span>
                <br />
                {{ flightDetail.dateTimeArriving | sabsDate : 5 }}
                <br />
                <a
                  tabindex="0"
                  (click)="getFareRules(flightIndex)"
                  (keydown.enter)="getFareRules(flightIndex); $event.stopPropagation()"
                  role="link"
                  class="fare_rules_link"
                  attr.aria-label="Fare rules for {{ flightDetail.destinationAirportName }} ({{
                    flightIndex + 1
                  }})"
                  ><i class="currency_info_icon"></i
                  ><span class="fare-rules-link-text" i18n="@@flightBasketFareRules"
                    >Fare Rules</span
                  ></a
                >
                <div class="resolve_loader_container" hidden="">
                  <div class="resolve_loader_loading_container">
                    <i class="button_spinner"></i
                    ><span
                      tabindex="0"
                      aria-atomic="true"
                      aria-live="assertive"
                      i18n="@@flightBasketGettingFareRules"
                      class="resolve_loader_text"
                      >Getting Fare Rules...</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let user of travellers; let index = index">
          <div *ngIf="extras.length && !duplicateBooking" class="pax-extras">
            <b>{{ user.forename }} {{ user.surname }}</b>
            <div
              *ngIf="
                extras[index].seatsOutbound.seats && extras[index].seatsOutbound.seats as seats
              ">
              <div *ngIf="seats?.seat" class="flex-row-container space-between">
                <div>
                  <span i18n="@@flightBasketOutboundSeat">Outbound seat</span
                  ><span>{{ seats.seat }}</span>
                </div>
                {{ seats.price | currency : seats.currency }}
              </div>
            </div>
            <div *ngFor="let extra of extras">
              <!-- additionalOutbound -->

              <div
                *ngIf="
                  user.id === extra.additionalOutbound?.advancedAncillaryOptions?.userId &&
                  extra.additionalOutbound?.advancedAncillaryOptions as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
              <div
                *ngIf="
                  user.id === extra.additionalOutbound?.baggageAllowance?.userId &&
                  extra.additionalOutbound?.baggageAllowance as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
              <div
                *ngIf="
                  user.id === extra.additionalOutbound?.flightExtras?.userId &&
                  extra.additionalOutbound?.flightExtras as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
              <div
                *ngIf="
                  user.id === extra.additionalOutbound?.reducedMobillityAssistance?.userId &&
                  extra.additionalOutbound?.reducedMobillityAssistance as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
            </div>
            <!-- additionalInbound -->

            <div
              *ngIf="extras[index].seatsInbound.seats && extras[index].seatsInbound.seats as seats">
              <div *ngIf="seats?.seat" class="flex-row-container space-between">
                <div>
                  <span i18n="@@flightBasketInboundSeat">Inbound seat</span
                  ><span>{{ seats.seat }}</span>
                </div>
                {{ seats.price | currency : seats.currency }}
              </div>
            </div>
            <div *ngFor="let extra of extras">
              <div
                *ngIf="
                  user.id === extra.additionalInbound?.advancedAncillaryOptions?.userId &&
                  extra.additionalInbound?.advancedAncillaryOptions as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
              <div
                *ngIf="
                  user.id === extra.additionalInbound?.baggageAllowance?.userId &&
                  extra.additionalInbound?.baggageAllowance as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
              <div
                *ngIf="
                  user.id === extra.additionalInbound?.flightExtras?.userId &&
                  extra.additionalInbound?.flightExtras as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
              <div
                *ngIf="
                  user.id === extra.additionalInbound?.reducedMobillityAssistance?.userId &&
                  extra.additionalInbound?.reducedMobillityAssistance as item
                "
                class="flex-row-container space-between">
                <div>
                  <span>{{ item.text }}</span>
                </div>
                {{ item.price | currency : item.currency }}
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="hasSeats && !isDuplicate">
          <span i18n="@@editSeats">Edit Seats and Extras</span>
          <button
            class="edit_seats_button"
            [ngbTooltip]="!disableEditButton ? 'Edit Seat Selection' : null"
            aria-label="Edit selected Seat Selection"
            i18n-ngbTooltip
            [disabled]="disableEditButton"
            [ngClass]="{ disableEditButton: disableEditButton }"
            placement="right"
            (click)="openSeatMapModal()">
            <i class="ico-edit" aria-hidden="true"></i>
          </button>
          <section
            *ngIf="hasSeats && disableEditButton"
            (mouseleave)="tt.close()"
            class="container">
            <i
              class="ico ico-info-circle info-button"
              i18n-ngbTooltip
              ngbTooltip="Seat and extra selections can no longer be edited. To make changes, please remove this flight and add it again to select new options"
              aria-label="Seat and extra selections can no longer be edited. To make changes, please remove this flight and add it again to select new options"
              triggers="manual"
              #tt="ngbTooltip"
              (mouseenter)="tt.open()"
              (focus)="tt.open()"
              (blur)="tt.close()"
              tabindex="0"
              ><span class="visuallyhidden"
                >Seat and extra selections can no longer be edited. To make changes, please remove
                this flight and add it again to select new options</span
              >
            </i>
          </section>
        </div>
      </div>
      <div class="cart_item_price">
        <strong *ngIf="duplicateBooking" i18n="@@flightBasketCost">Cost</strong>
        <strong>{{ flight.price | currency : flight.currency }}</strong
        ><em *ngIf="!duplicateBooking" i18n="@@flightBasketTotalPrice"> Total Price</em>
      </div>
    </ng-container>
  </div>
</div>
